<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.65 159.65">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M79.82,159.65A79.83,79.83,0,1,0,0,79.82,79.92,79.92,0,0,0,79.82,159.65Zm0-151A71.18,71.18,0,1,1,8.63,79.82,71,71,0,0,1,79.82,8.63Zm26.07,97.62a8.63,8.63,0,1,1,8.63-8.63A8.63,8.63,0,0,1,105.89,106.25Zm-53.39,0a8.63,8.63,0,1,1,8.62-8.63A8.63,8.63,0,0,1,52.5,106.25ZM80,35.6A50.11,50.11,0,0,0,38.12,58.31L45.37,63a41,41,0,0,1,68.91,0l7.25-4.69a50.13,50.13,0,0,0-36-22.39A47.57,47.57,0,0,0,80,35.6Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UpsidedownSmile'
}
</script>