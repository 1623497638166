<template>
  <div
    class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50"
    @click.self="$emit('close')"
  >
    <div class="content relative">
      <UpsidedownSmile v-if="success" class="w-12 rotate-180 mb-8" />
      <CrossEyedFace v-else-if="failureIcon === 'CrossEyedFace'" class="w-12 mb-8" />
      <StraightFace v-else class="w-12 mb-8" />
      <div class="heading">{{ text }}</div>
      <div v-if="!hideActions">
        <div v-if="actions === 1">
          <p class="text-md text-center px-8 py-4 mt-4 cursor-pointer" @click.self="$emit('confirm')">{{ buttonText }} ></p>
        </div>
        <div v-else class="flex justify-between w-52 mt-8">
          <div class="round-btn" @click="$emit('cancel')">
            <div>NO</div>
          </div>
          <div class="round-btn" @click="$emit('confirm')">
            <div>SI</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpsidedownSmile from '@/components/shared/icons/UpsidedownSmile.vue'
import CrossEyedFace from '@/components/shared/icons/CrossEyedFace.vue'
import StraightFace from '@/components/shared/icons/StraightFace.vue'
export default {
  name: 'BlackModal',
  components: { UpsidedownSmile, CrossEyedFace, StraightFace },
  props: {
    success: { type: Boolean, default: true },
    failureIcon: { type: String, default: 'CrossEyedFace' },
    hideActions: { type: Boolean, default: false },
    actions: { type: Number, default: 1 },
    text: { type: String, default: '' },
    buttonText: { type: String, default: 'SEGUIR' }
  }
}
</script>

<style lang="scss" scoped>
.content {
  @apply w-5/6 text-white bg-black rounded-xl flex flex-col items-center justify-center py-12 px-4;
  max-width: 280px;
  font-family: WestmountExtraBold;
}

.heading {
  @apply uppercase text-center;
  font-size: 1.75rem;
  line-height: 1;
}

.round-btn {
  @apply relative bg-white flex items-center justify-center text-black cursor-pointer;
  width: 4.5rem;
  height: 4.5rem;
  font-size: 2rem;
  border-radius: 50%;

  div {
    @apply absolute;
    transform: translateY(-6px);
    letter-spacing: -2px;
  }
}
</style>
