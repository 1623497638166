<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.49 189.49">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          style="fill: #fff"
          d="M94.75,0a94.75,94.75,0,1,0,94.74,94.75A94.85,94.85,0,0,0,94.75,0Zm0,179.25a84.26,84.26,0,0,1-59.56-24.61,85.34,85.34,0,0,1-15.32-20.75A84.46,84.46,0,0,1,152.06,32.71,85.07,85.07,0,0,1,171,58.32,84.49,84.49,0,0,1,94.75,179.25Z"
        />
        <line class="cross-eyed-cls-2" x1="46.98" y1="59.22" x2="73.66" y2="85.9" />
        <line class="cross-eyed-cls-2" x1="73.66" y1="59.22" x2="46.98" y2="85.9" />
        <line class="cross-eyed-cls-2" x1="115.83" y1="59.22" x2="142.51" y2="85.9" />
        <line class="cross-eyed-cls-2" x1="142.51" y1="59.22" x2="115.83" y2="85.9" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CrossEyedFace'
}
</script>

<style>
.cross-eyed-cls-2 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 9.7px;
}
</style>
