<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.49 189.49">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="straight-face-cls-1"
        d="M94.75,0a94.75,94.75,0,1,0,94.74,94.75A94.85,94.85,0,0,0,94.75,0Zm0,179.25a84.26,84.26,0,0,1-59.56-24.61,85.34,85.34,0,0,1-15.32-20.75A84.46,84.46,0,0,1,152.06,32.71,85.07,85.07,0,0,1,171,58.32,84.49,84.49,0,0,1,94.75,179.25ZM63.8,63.38A10.24,10.24,0,1,1,53.56,73.62,10.24,10.24,0,0,1,63.8,63.38Zm63.38,0a10.24,10.24,0,1,1-10.24,10.24A10.24,10.24,0,0,1,127.18,63.38Z" />
      <line class="straight-face-cls-2" x1="50.83" y1="130.98" x2="138.66" y2="130.98" />
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: 'StraightFace'
}
</script>

<style>
  .straight-face-cls-1 {
    fill: #fff;
  }
  .straight-face-cls-2 {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 12px;
  }
</style>
