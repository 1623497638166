<template>
  <div class="relative z-20">
    <div v-if="recurso">
      <ArrowButton
        :text="recurso.name"
        rotate="90deg"
        background="white"
        color="black"
        @onClick="toggleOptionsVisible"
      />
    </div>
    <div
      v-if="optionsVisible"
      class="absolute w-full flex flex-col gap-1 bg-white"
      :class="recurso ? 'top-11' : 'top-0'"
    >
      <ArrowButton
        v-for="recurso in employees"
        :key="recurso.name"
        :text="recurso.name"
        color="#000000"
        background="#cccccc"
        rotate="-90deg"
        @onClick="selectRecurso(recurso)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ArrowButton from '@/components/shared/ArrowButton.vue'

export default {
  name: 'SeleccionRecurso',
  components: { ArrowButton },
  props: {
    recurso: { type: Object, default: null },
    store: { type: String, default: 'ingresoTrabajo' },
    resetStateOnMenuOpen: { type: Boolean, default: false }
  },

  data() {
    return {
      optionsVisible: true
    }
  },

  computed: {
    ...mapGetters({ employees: 'employees/employees' })
  },

  async mounted() {
    if (!this.employees) {
      await this.getEmployees()
    }

    if (this.recurso) {
      this.optionsVisible = false
    }
  },

  methods: {
    ...mapActions({
      getEmployees: 'employees/getEmployees'
    }),

    ...mapMutations({
      setRecursoIngresoTrabajo: 'ingresoTrabajo/setRecurso',
      setRecursoEvaluacionSemanal: 'evaluacionSemanal/setRecurso'
    }),

    selectRecurso(recurso) {
      this.setRecurso(recurso)
      this.optionsVisible = false
    },

    setRecurso(recurso) {
      if (this.store === 'ingresoTrabajo') {
        this.setRecursoIngresoTrabajo(recurso)
      } else {
        this.setRecursoEvaluacionSemanal(recurso)
      }
    },

    toggleOptionsVisible() {
      this.optionsVisible = !this.optionsVisible

      if (this.resetStateOnMenuOpen) {
        this.setRecurso(null)
      }
    }
  }
}
</script>
