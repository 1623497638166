<template>
  <div>
    <button
      class="w-full flex justify-between items-center px-4 py-2 bg-white text-black"
      @click="goBack"
    >
      <div class="chevron"><Chevron /></div>
      <div class="button-text">{{ text || 'VOLVER' }}</div>
    </button>
  </div>
</template>

<script>
import Chevron from '@/components/shared/icons/Chevron.vue'
export default {
  name: 'BackButton',
  components: { Chevron },
  props: {
    route: { default: null },
    text: { type: String, default: null },
    onClickEmit: { type: Boolean, default: false }
  },
  methods: {
    goBack() {
      if (this.onClickEmit) {
        this.$emit('onClick')
      } else {
        if (this.route) {
          this.$router.push(this.route)
        } else {
          this.$router.back()
        }
      }
    }
  }
}
</script>

<style scoped>
.chevron {
  width: 10px;
  transform: rotate(180deg);
}

.button-text {
  @apply text-lg;
  font-family: ProximaNovaSemiBold;
  letter-spacing: 1px;
  text-transform: uppercase;
}
</style>
